<template>
    <div id="history" class="bg-gray-relative-100">
        <div class="pb-3">
            <SubHeader title="결제 상세 정보"></SubHeader>
        </div>
        <div class="px-3">
            <div class="p-3 mb-3 bg-body rounded-4 shadow-400">
                <div class="fs-px-15 lh-lg fw-bold flex-between-center">
                    <p class="" v-if="info.type == 'D'">
                        {{ addDay(info.date) }} {{ info.confirm_time }}
                    </p>
                    <p class="" v-if="info.type == 'R'">
                        {{ addDay(info.date) }}
                        <span v-if="info.status != 'H'">{{ info.confirm_time }}</span>
                        <span v-if="info.status == 'H'">{{ info.start_time }} ~ {{ info.end_time }}</span>
                    </p>
                </div>
                <div class="flex-between-center mb-2 flex-wrap fs-px-14">
                    <span class="badge badge-custom-1">{{ info.memo }} 코스</span>
                    <span class="" >
                        {{ info.proxy == 'N' ? '본인' : proxy_list[0].name }} <span v-if="info.people >1"> 외 {{ info.people - 1 }}명</span>
                    </span>
                </div>

                <div class="w-100 flex-between-center position-relative">
                    <div class="bg-img w-px-70 h-px-70 rounded-4 shadow flex-shrink-0" :style="{ backgroundImage: 'url(' + l_image_path + ')' }"></div>
                    <div class="txt_box small w-80 ps-3">
                        <p class="h6 fw-bold lh-lg" :class="{ 'text-success': info.status === 'Y', 'text-primary': info.status === 'P', 'text-gray-500': info.status === 'H', 'text-danger': info.status === 'C', 'text-warning': info.status === 'CP',}">
                            {{ info.status === 'Y' ? '결제 완료' : info.status === 'H' ? '결제 대기' : info.status === 'P' ? '결제 요청' : info.status === 'CP' ? '취소 요청' : '취소 완료'}}
                        </p>
                        <div class="d-flex gap-3 mb-1">
                            <p class="h6 fw-bold">{{ l_name }}</p>
                            <div class="text-gray-relative-600 flex-between-center w-50 pe-2">
                                <a :href="info.site_url" target="_blank">
                                    <i class="fas fa-link"></i>
                                </a>
                            </div>
                        </div>
                        <p class="lh-sm text-gray-relative-600">
                            <i class="far fa-map-marker-alt me-1"></i>{{ l_address }}
                        </p>
                    </div>

                    <div class="position-absolute end-0">
                        <span class="badge w-px-30 h-px-30 rounded-3 fw-normal fs-px-12 px-2 ms-3 d-flex flex-column align-items-center justify-content-center shadow-100 border" role="button" @click="$router.push(`/payment/receipt/${info.code}`)" v-if="info.status =='Y'">
                            <i class="fal fa-receipt text-muted"></i>
                        </span>
                    </div>
                </div>
                <div class="flex-between-center pt-3 fs-px-14" v-if="info.status === 'C'">
                    <small>결제 취소 일시</small>
                    <small class="small">{{ addDay(info.update_time) }}</small>
                </div>
                <hr />

                <div class="text-start fs-px-15 mb-3">
                    <div class="flex-between-center">
                        <b>결제 요청 금액</b>
                        <p role="button" @click="detailOpen = !detailOpen">
                            <b class="text-success" v-if="info.status !== 'C'">{{ CheckPrice(info.price) }} 원</b>
                            <b class="text-success" v-if="info.status === 'C'">-{{ CheckPrice(info.price) }} 원</b>
                            <i class="fas fa-sm text-gray-relative-500" :class="{ 'fa-chevron-down': !detailOpen, 'fa-chevron-up': detailOpen,}"></i>
                        </p>
                    </div>
                    <div class="fs-px-13 flex-between-center" v-show="detailOpen">
                        <span class="ps-2">
                            └ 그린피 
                            <span v-show="info.cart == 'Y'">+ 카트비</span>
                        </span>
                        <p class="pe-3" v-if="info.status !== 'C'">
                            {{ CheckPrice(info.price) }} 원
                        </p>
                        <p class="pe-3" v-if="info.status === 'C'">
                            -{{ CheckPrice(info.price) }} 원
                        </p>
                    </div>
                </div>

                <div class="text-start fs-px-15 mb-3" v-if="info.proxy == 'Y'">
                    <div class="flex-between-center">
                        <b>라운딩 참석자 (대리 결제)</b>
                    </div>
                    <div class="fs-px-13 flex-between-center" v-show="detailOpen">
                        <div class="w-50 d-flex flex-column">
                            <div class="d-flex gap-3" v-for="item in proxy_list" :key="item.no">
                                <span>{{ item.name }}</span>
                                <span>{{ phoneFormat(item.tel) }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text-start fs-px-15 mb-3" v-show="info.other == 'Y'">
                    <div class="flex-between-center">
                        <b>기타 요청 사항</b>
                    </div>
                    <div class="fs-px-13 flex-between-center" v-show="detailOpen">
                        <span class="ps-2">
                            <span>{{ info.other_memo }}</span>
                        </span>
                    </div>
                </div>

                <div class="text-start fs-px-15 mb-3" v-show="info.status == 'C' || info.status == 'CP'">
                    <div class="flex-between-center">
                        <b>취소 사유</b>
                    </div>
                    <div class="fs-px-13 flex-between-center" v-show="detailOpen">
                        <span class="ps-2">
                            <span>{{ info.cancel_memo }}</span>
                        </span>
                    </div>
                </div>

                <div class="text-start fs-px-15 mb-3" v-if="info.status == 'CP'">
                    <div class="flex-between-center">
                        <b v-if="info.status == 'CP'">취소 요청 일시</b>
                    </div>
                    <div class="fs-px-13 flex-between-center" v-show="detailOpen">
                        <span class="ps-2">
                            <span>{{ addDay(info.update_time) }}</span>
                        </span>
                    </div>
                </div>
            </div>
            
            <div class="d-flex">
                <button class="btn btn-main thick w-100 btn-lg text-white shadow-sm me-2" @click="EditReservation()" v-if="info.status =='P'">결제요청 변경</button>
                <button class="btn btn-warning thick w-100 btn-lg text-white shadow-sm ms-2" @click="CancelReservation()" v-if="info.status !='C' && info.status !='Y' && info.status !=''">결제요청 취소</button>
                <button class="btn btn-danger thick w-100 btn-lg text-white shadow-sm ms-2" @click="CancelReservationRefund()" v-if="info.status =='Y'">결제요청 취소</button>
            </div>
            
        </div>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue';
import { format } from '@/mixins';
const CryptoJS = require('crypto-js');

export default {
    components: {
        SubHeader,
    },
    mixins: [format],
    data() {
        return {
            code: this.$route.params.code,
            detailOpen: true,
            info: {
                code: '',
                type: '',
                shop_code: '',
                location_code: '',
                member_code: '',
                date: '',
                start_time: '',
                end_time: '',
                confirm_time: '',
                people: 0,
                memo: '',
                price: 0,
                proxy: '',
                cart: '',
                other: '',
                other_memo: '',
                status: '',
                create_time: '',
                update_time: '',
                cancel_memo: null,
                user_name: '',
                location_name: '',
                location_address: '',
                site_url: '',
            },
            l_name: '',
            l_address: '',
            l_image_path: '',
            proxy_list: [{ no: 0, name: '', tel: '' }],
            proxy_name: '',
        };
    },
    mounted() {
        this.GetReservation();
    },
    methods: {
        EditReservation(){
            const code = this.code;
            this.$router.push({path : `/reservation/edit/${code}`})
        },
        CheckPrice(price) {
            const amount = price * 1;
            return amount.toLocaleString();
        },
        CancelReservationRefund(){

            this.$confirm('결제를 취소하시겠습니까?').then((result) => {
                if (result.isOk) {
                    const code = this.code;

                    
                    const body = { code };
                    const req = CryptoJS.AES.encrypt( JSON.stringify(body), process.env.VUE_APP_SECRET_TOKEN).toString();

                    this.$http.post('/shop/reservation/ReservationCancelRefund', { req }).then(
                        (res) => {
                            if(res.status == 200){
                                // this.loading = false;
                                if(res.data.code =="200"){
                                    this.GetReservation();
                                }
                            }

                        }
                    );
                }
            });
            
        },
        CancelReservation(){

            this.$confirm('예약을 취소하시겠습니까?').then((result) => {
                if (result.isOk) {
                    const code = this.code;

                    const body = { code };
                    const req = CryptoJS.AES.encrypt( JSON.stringify(body), process.env.VUE_APP_SECRET_TOKEN).toString();

                    this.$http.post('/shop/reservation/ReservationCancel', { req }).then(
                        (res) => {
                            if(res.status == 200){
                                // this.loading = false;
                                if(res.data.code =="200"){
                                    this.GetReservation();
                                }
                            }

                        }
                    );
                }
            });
            
        },
        GetReservation() {
            const code = this.code;

            const body = { code };
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            
            this.$http.post('/shop/reservation/GetReservationDetail', { req }).then(
                (res) => {
                    const e_body = res.data.body;
                    const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res);

                    this.info = body.info;
                    this.l_name = body.l_info.name;
                    this.l_address = body.l_info.address;
                    this.l_image_path = body.l_info.image_path;
                    this.proxy_list = body.proxy_list;
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
#history {
  min-height: calc(100vh - 70px) !important;
  padding-bottom: 20px;
}
.badge-custom-1 {
  border: 1px solid #52c19c;
  color: #52c19c;
  line-height: 12px;
  font-weight: 600;
  background-color: #68d4ab13;
}
.gradient-danger {
  background: linear-gradient(to bottom, #fc6e51, #e9573f);
}
.gradient-success {
  background: linear-gradient(to bottom, #a0d468, #8cc152);
}
</style>
